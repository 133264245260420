<template>
    <div class="mx-auto min-h-screen max-w-screen-2xl overflow-clip px-5 py-5 lg:px-8 lg:py-9">
        <div
            class="hero-box grid h-full max-h-17 min-h-16 place-items-center gap-4 bg-blue-900 bg-opacity-50">
            <img
                class="pointer-events-none absolute -z-10 h-full w-full object-cover object-center"
                src="~/assets/images/INSTADRIVE_Start.jpg"
                alt="INSTADRIVE Start Background" />

            <IconsLogosInstadriveFull class="absolute left-6 top-6 w-13 fill-shades-white" />
            <div class="flex flex-col gap-6">
                <h1 class="text-center text-shades-white">
                    <span class="sr-only">INSTADRIVE:</span> E-Auto Leasing zum Bestpreis
                </h1>
                <div
                    class="flex flex-col gap-4 rounded-3xl bg-blue-100 bg-opacity-60 p-6 text-blue-900 shadow-xl backdrop-blur">
                    <h2 class="text-xl font-bold lg:text-2xl">Bitte wähle ein Land aus:</h2>
                    <div
                        class="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 md:gap-7 lg:grid-cols-4">
                        <a
                            v-for="country in countries"
                            :key="country.id"
                            :href="`${config.public.hostName}/${country.cc}/`"
                            class="flex items-center justify-center gap-4 rounded-3xl bg-shades-white p-5 text-blue-900 shadow-md transition-all duration-300 hover:shadow-xl">
                            <component
                                :is="atFlag"
                                v-if="country.cc === 'at'"
                                class="h-7 w-7"></component>
                            <component
                                :is="deFlag"
                                v-else-if="country.cc === 'de'"
                                class="h-7 w-7"></component>
                            <component
                                :is="chFlag"
                                v-else-if="country.cc === 'ch'"
                                class="h-7 w-7"></component>
                            <component
                                :is="frFlag"
                                v-else-if="country.cc === 'fr'"
                                class="h-7 w-7"></component>
                            <span class="font-bold text-blue-900">{{ country.name }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { Country } from '~/types/enums/Country';

    const config = useRuntimeConfig();
    const currentCountry = useCurrentCountry();

    currentCountry.value = Country.NONE;

    const atFlag = resolveComponent('icons/at-flag');
    const deFlag = resolveComponent('icons/de-flag');
    const chFlag = resolveComponent('icons/ch-flag');
    const frFlag = resolveComponent('icons/fr-flag');

    const countries = [
        { id: 1, name: 'Deutschland', cc: 'de' },
        { id: 2, name: 'Österreich', cc: 'at' },
        { id: 3, name: 'Schweiz', cc: 'ch' },
        { id: 4, name: 'France', cc: 'fr' },
    ];

    definePageMeta({
        layout: false,
    });

    useHead({
        link: [
            {
                rel: 'alternate',
                hreflang: 'x-default',
                href: `${config.public.hostName}`,
            },
        ],
    });

    useSeoMeta({
        title:
            config.public.siteMode === 'B2B'
                ? 'INSTADRIVE E-Autos Firmenwagen'
                : 'INSTADRIVE E-Autos im Abo',
        description:
            'Rundum versorgt: Versicherung, Wartung & Co. Individuell konfigurieren & online bestellen. Einfach, bequem, nachhaltig: Dein E-Auto Abo bei INSTADRIVE. Beratungstermin vereinbaren.',
        ogTitle: 'Elektroauto Firmenwagen',
        ogDescription:
            'Rundum versorgt: Versicherung, Wartung & Co. Individuell konfigurieren & online bestellen. Einfach, bequem, nachhaltig: Dein E-Auto Abo bei INSTADRIVE. Beratungstermin vereinbaren.',
    });
</script>
